<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <select-component
                        name="enterprise_id"
                        lable="申请企业"
                        :farr="all_enterprise"
                        :fvalue_name="details.enterprise_name"
                        @clk="confirm_enterprise"
                    ></select-component>
                    <select-component
                        name="department_id"
                        lable="申请部门"
                        :farr="all_department"
                        :fvalue_name="details.department_name"
                        @clk="confirm_department"
                    ></select-component>
                    <select-component
                        name="staff_id"
                        lable="申请人"
                        :farr="all_staff"
                        :fvalue_name="details.staff_name"
                        @clk="confirm_staff"
                    ></select-component>
                    <select-component
                        name="category"
                        lable="申请类型"
                        :farr="all_currency_category"
                        :fvalue_name="details.category"
                        @clk="confirm_category"
                    ></select-component>
                    <van-field
                        v-model="details.title"
                        name="title"
                        label="标题"
                        placeholder="标题"
                        :rules="[{ required: true, message: '请填请标题' }]"
                    />
                    <van-field
                        type="textarea"
                        v-model="details.cause"
                        name="cause"
                        label="申请原因"
                        placeholder="申请原因"
                        :rules="[{ required: true, message: '请填写采购原因' }]"
                    />
                    <van-field
                        type="textarea"
                        v-model="details.content"
                        name="content"
                        label="其它说明"
                        placeholder="采购清单"
                        :rules="[{ required: true, message: '请填写其它说明' }]"
                    />
                    <van-cell title="上传附件">
                        <template #label>
                            <uploader-component
                                :farr="details.other"
                                url="currency/upload"
                                @update_img="update_img"
                            ></uploader-component>
                        </template>
                    </van-cell>
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="申请单号" :value="details.name" />
                <van-cell title="申请类型" :value="details.category_name" />
                <van-cell title="状态">
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="details.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="details.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="details.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="details.state===3">已驳回</van-tag>
                    </template>
                </van-cell>
                <van-cell title="申请企业" :value="details.enterprise_name" />
                <van-cell title="申请部门" :value="details.department_name" />
                <van-cell title="申请人" :value="details.staff_name" />
                <van-cell title="申请日期" :value="details.apply_date" />
                <van-cell title="标题" :value="details.title" />
                <van-cell title="申请原因" :value="details.cause" />
                <van-cell title="其它说明" :value="details.content" />
                <van-cell title="附件">
                    <template #label>
                        <uploader-component
                            :farr="details.other"
                            :show_upload="false"
                            :deletable="false"
                        ></uploader-component>
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="to_approval_auth" round block type="primary" @click="to_approval()">提交审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="sel_approval_auth" round block type="warning" @click="sel_approval()">查看审批</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="withdraw_auth" round block type="danger" @click="currencyWithdraw()">审批撤回</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="edit_auth" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="del_auth" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { 
    currency_details_request,
    currency_del_request,
    currency_edit_request,
    currency_add_request,
    currency_to_approval_request,
    currency_withdraw_request
    } from '@/network/finance/Currency.js'
import { enterprise_list_request,department_list_request,staff_list_request,currency_category_list_request } from '@/network/list.js'

export default {
    name:'CurrencyDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                enterprise_id: this.$store.state.user.enterprise_id,
                enterprise_name: this.$store.state.user.enterprise_name,
                department_id: this.$store.state.user.department_id,
                department_name: this.$store.state.user.department_name,
                category: '',
                title: '',
                cause: '',
                content: '',
                staff_id: this.$store.state.user.id,
                staff_name: this.$store.state.user.name,
                other: []
            },
            edit: false,
            all_enterprise: [],
            all_department: [],
            all_staff: [],
            all_currency_category: [],
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改印章使用申请' : '新增印章使用申请') : '印章使用申请详情'
        }
    },
    methods:{
        get_currency_details() {
            this.$store.commit('true_loading')
            currency_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => {
                    this.$store.commit('false_loading')
                    this.is_auth()
                })
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                currency_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        back_details() {
            this.get_currency_details()
            this.edit = false
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                department_id: this.details.department_id,
                category: this.details.category,
                title: this.details.title,
                cause: this.details.cause,
                content: this.details.content,
                staff_id: this.details.staff_id,
                other: this.details.other
            }
            currency_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_currency_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                department_id: this.details.department_id,
                category: this.details.category,
                title: this.details.title,
                cause: this.details.cause,
                content: this.details.content,
                staff_id: this.details.staff_id,
                other: this.details.other
            }
            currency_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_currency_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        sel_approval() {
            this.$router.push({
                name: 'ApprovalComponent',
                query: {
                    type: 17,
                    id: this.id,
                    ApprovalAuth: this.ApprovalAuth
                }
            })
        },
        to_approval() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定提交审批？',
            })
            .then(() => {
                this.$store.commit('true_loading')
                currency_to_approval_request({id:this.id,note:''})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已提交!')
                                this.get_currency_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        currencyWithdraw() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定撤回？（撤回后再发起需要重新走流程）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                currency_withdraw_request({id:this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('已撤回!')
                                this.get_currency_details()
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_currency_category() {
            this.$store.commit('true_loading')
            currency_category_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_currency_category = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_enterprise(e) {
            this.details.enterprise_id = e.id
            this.details.department_id = ''
            department_list_request({enterprise_id: e.id})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_department = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_department(e) {
            this.details.department_id = e.id
        },
        confirm_category(e) {
            this.details.category = e.id
        },
        confirm_staff(e) {
            this.details.staff_id = e.id
        },
        update_img(arr) {
            this.details.other = arr
        },
        is_auth() {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(this.details.state === 0) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交印章使用申请')
                    this.edit_auth = this.$_has('修改印章使用申请')
                    this.del_auth = this.$_has('删除印章使用申请')
                }
            } else if(this.details.state === 1) {
                 //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回印章使用申请')
                }
                //判断当前审批人是否为自己
                if(this.details.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批印章使用申请')
                }
                this.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
            } else if(this.details.state === 2) {
                this.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
            } else if(this.details.state === 3) {
                //判断创建人或申请人是否为自己
                if((this.details.staff_id === this.$store.state.user.id) || (this.details.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交印章使用申请')
                    this.edit_auth = this.$_has('修改印章使用申请')
                    this.del_auth = this.$_has('删除印章使用申请')
                }
                this.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
            }
        },
    },
    filters:{},
    props:{},
    created(){
        this.id = this.$route.query.id
        if(this.id) {
            this.get_currency_details()
        }
        else
        {
            this.edit = true
        }

        this.get_all_enterprise()
        this.get_all_staff()
        this.get_all_currency_category()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
.van-cell__value {
    white-space: pre-line
}
</style>